import { Routes, Route } from "react-router-dom";

import Gallery from "./components/gallery";
import Landing from "./components/landing";
import Footer from "./components/footer";
import About from "./components/about";
import Contact from "./components/contact";

function App() {
    return (
        <div className="bg-white h-screen">
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
            </Routes>
        </div>
    );
}

export default App;
