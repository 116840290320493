import NavBar from "../components/navBar";
import Fondo from "../assets/video/NorthLights_Demo.mov";
import Gallery from "../components/gallery";
import Footer from "../components/footer";

export default function Landing() {
    return (
        <div className="relative">
            <NavBar />
            <video autoPlay loop muted>
                <source src={Fondo} type="video/mp4" />
            </video>
            <Gallery />
            <Footer />
        </div>
    );
}
