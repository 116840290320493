import { galleryThumbnails } from "../data/gallery_thumbnails";
import { galleryFull } from "../data/gallery_full_images";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function Gallery() {
    const [clickedImg, setClickImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    function handleClick(item, index) {
        setCurrentIndex(index);
        setClickImg(item.image);
        setIsOpen(true);
    }
    //Close image
    function handleClose() {
        setIsOpen(false);
    }
    //Previous image
    function handlePreviousImage() {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }
    //Next image
    function handleNextImage() {
        if (currentIndex < galleryFull.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    }
    return (
        <div>
            {isOpen && (
                <div
                    className=" fixed top-0 bottom-0 left-0 right-0 z-40 bg-slate-900/90 
                flex justify-center items-center"
                >
                    <img
                        src={galleryFull[currentIndex].image}
                        alt={galleryFull[currentIndex].description}
                        className="max-h-screen border-8"
                        onClick={handleNextImage}
                    />

                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        size="2x"
                        onClick={handleClose}
                        className="fixed z-50 text-orange-500 bottom-10"
                    />
                </div>
            )}
            <div className="grid grid-cols-3 font-raleway my-16">
                <div>
                    <h1 className="text-5xl ps-6">Work</h1>
                    <h2 className="text-l ps-6">
                        Below there is an example of our previous comissioned
                        and concept work.
                    </h2>
                </div>
                <div></div>
                <div></div>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 p-8 gap-3 justify-center">
                {galleryThumbnails.map((item, index) => (
                    <div className="relative" key={index}>
                        <img
                            className="transition hover:opacity-40 duration-700  rounded"
                            src={item.image}
                            alt="item.description"
                        />
                        <button
                            className="p-2 bg-white  font-raleway absolute  opacity-0 hover:opacity-70 transition duration-700 h-full w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                            onClick={() => handleClick(item, index)}
                        >
                            {item.description}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
