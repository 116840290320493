import logoWhite from "../assets/images/brand/Logo_Name_transparency white.png";
import { Link } from "react-router-dom";

export default function NavBar() {
    return (
        <div className="absolute z-50">
            <div className="p-5 text-white">
                <div className="">
                    <img
                        src={logoWhite}
                        alt="North Lights Logo"
                        className="w-[400px]"
                    />
                </div>
                {/* <div className="font-navBar font-bold text-sm flex gap-4 mt-4">
                    <p>[</p>
                    <Link to="/about">ABOUT</Link>
                    <Link to="/contact">CONTACT</Link>

                    <p>]</p>
                </div> */}
            </div>
        </div>
    );
}
