import image_01 from "../assets/images/work/thumbnails/01.jpg";
import image_02 from "../assets/images/work/thumbnails/02.jpg";
import image_03 from "../assets/images/work/thumbnails/03.jpg";
import image_04 from "../assets/images/work/thumbnails/04.jpg";
import image_05 from "../assets/images/work/thumbnails/05.jpg";
import image_06 from "../assets/images/work/thumbnails/06.jpg";
import image_07 from "../assets/images/work/thumbnails/07.jpg";
import image_08 from "../assets/images/work/thumbnails/08.jpg";
import image_09 from "../assets/images/work/thumbnails/09.jpg";
import image_10 from "../assets/images/work/thumbnails/10.jpg";
import image_11 from "../assets/images/work/thumbnails/11.jpg";
import image_12 from "../assets/images/work/thumbnails/12.jpg";
import image_13 from "../assets/images/work/thumbnails/13.jpg";
import image_14 from "../assets/images/work/thumbnails/14.jpg";
import image_15 from "../assets/images/work/thumbnails/15.jpg";
import image_16 from "../assets/images/work/thumbnails/16.jpg";
import image_17 from "../assets/images/work/thumbnails/17.jpg";
import image_18 from "../assets/images/work/thumbnails/18.jpg";
import image_19 from "../assets/images/work/thumbnails/19.jpg";
import image_20 from "../assets/images/work/thumbnails/20.jpg";
import image_21 from "../assets/images/work/thumbnails/21.jpg";
import image_22 from "../assets/images/work/thumbnails/22.jpg";
import image_23 from "../assets/images/work/thumbnails/23.jpg";
import image_24 from "../assets/images/work/thumbnails/24.jpg";
import image_25 from "../assets/images/work/thumbnails/25.jpg";
import image_26 from "../assets/images/work/thumbnails/26.jpg";
import image_27 from "../assets/images/work/thumbnails/27.jpg";
import image_28 from "../assets/images/work/thumbnails/28.jpg";
import image_29 from "../assets/images/work/thumbnails/29.jpg";
import image_30 from "../assets/images/work/thumbnails/30.jpg";
import image_31 from "../assets/images/work/thumbnails/31.jpg";
import image_32 from "../assets/images/work/thumbnails/32.jpg";
import image_33 from "../assets/images/work/thumbnails/33.jpg";
import image_34 from "../assets/images/work/thumbnails/34.jpg";
import image_35 from "../assets/images/work/thumbnails/35.jpg";
import image_36 from "../assets/images/work/thumbnails/36.jpg";
import image_37 from "../assets/images/work/thumbnails/37.jpg";
import image_38 from "../assets/images/work/thumbnails/38.jpg";
import image_39 from "../assets/images/work/thumbnails/39.jpg";
import image_40 from "../assets/images/work/thumbnails/40.jpg";
import image_41 from "../assets/images/work/thumbnails/41.jpg";
import image_42 from "../assets/images/work/thumbnails/42.jpg";
import image_43 from "../assets/images/work/thumbnails/43.jpg";
import image_44 from "../assets/images/work/thumbnails/44.jpg";
import image_45 from "../assets/images/work/thumbnails/45.jpg";
import image_46 from "../assets/images/work/thumbnails/46.jpg";
import image_47 from "../assets/images/work/thumbnails/47.jpg";
import image_48 from "../assets/images/work/thumbnails/48.jpg";
import image_49 from "../assets/images/work/thumbnails/49.jpg";
import image_50 from "../assets/images/work/thumbnails/50.jpg";
import image_51 from "../assets/images/work/thumbnails/51.jpg";

export const galleryThumbnails = [
    {
        name: "Changi Airport",
        description: "Changi Airport Departures",
        image: image_01,
    },
    {
        name: "Tower 28 NY",
        description: "Tower 28, Long Island, NY",
        image: image_02,
    },
    {
        name: "King St Lobby",
        description: "King St Lobby, MA",
        image: image_03,
    },
    {
        name: "Durham ID",
        description: "Durmham ID",
        image: image_04,
    },
    {
        name: "225 Park",
        description: "225/223 Park Ave, NY, connector",
        image: image_05,
    },

    {
        name: "225 Park Lobby",
        description: "225 Park Ave, NY, Lobby",
        image: image_06,
    },
    {
        name: "225 Park Office",
        description: "225 Park Ave, NY, Office concept",
        image: image_07,
    },
    {
        name: "YC",
        description: "Yellowstone Club, Montana, Locker room",
        image: image_08,
    },
    {
        name: "PUMA concept",
        description: "PUMA campus, concept",
        image: image_09,
    },
    {
        name: "Residence",
        description: "146 Beacon, MA",
        image: image_10,
    },
    {
        name: "Autodesk Infraestructure",
        description: "Autodesk Infraestructure marketing piece",
        image: image_11,
    },
    {
        name: "Cumberland community",
        description: "Cumberland Community, San Diego, CA",
        image: image_12,
    },
    {
        name: "Bananaland",
        description: "Bananaland website landing concept",
        image: image_13,
    },
    {
        name: "Dormitorios Tec",
        description: "Dormitorios ITESM, Mty, Mex.",
        image: image_14,
    },
    {
        name: "Carson & Tryon",
        description: "Carson & Tryon, Crescent Communities",
        image: image_15,
    },
    {
        name: "YC",
        description: "Yellowstone Club, Montana, Office Lobby",
        image: image_16,
    },
    {
        name: "YC",
        description: "Yellowstone Club, Montana, Restaurant",
        image: image_17,
    },
    {
        name: "King St",
        description: "King St, Lobby VR",
        image: image_18,
    },
    {
        name: "Gym VR",
        description: "Gym VR, concept design",
        image: image_19,
    },
    {
        name: "King St",
        description: "King St, Main entrance",
        image: image_20,
    },
    {
        name: "Zarodof",
        description: "Zarodof Residence, powder room",
        image: image_21,
    },
    {
        name: "San Pedro tower",
        description: "Tower concept, San Pedro, Mex.",
        image: image_22,
    },
    {
        name: "Ludlow",
        description: "The Ludlow Apartments, PI, Social Area",
        image: image_23,
    },
    {
        name: "Ludlow",
        description: "The Ludlow Apartments, PI, Top roof",
        image: image_24,
    },
    {
        name: "Residence Montana",
        description: "Portillo Residence, Montana",
        image: image_25,
    },
    {
        name: "Portovera",
        description: "Portovera Pool, Ver, Mex",
        image: image_26,
    },
    {
        name: "Retail",
        description: "Retail concept interior",
        image: image_27,
    },
    {
        name: "Centre 425",
        description: "Centre 425 interior, Bellevue",
        image: image_28,
    },
    {
        name: "Centre 425",
        description: "Centre 425 exterior, Bellevue",
        image: image_29,
    },
    {
        name: "Portovera",
        description: "Portovera Sauna, Ver, Mex",
        image: image_30,
    },
    {
        name: "Atrium",
        description: "Atrium interior,Cambridge, MA",
        image: image_31,
    },
    {
        name: "Window profile",
        description: "Window profile, product viz",
        image: image_32,
    },
    {
        name: "King Street",
        description: "King Street full entrance",
        image: image_33,
    },
    {
        name: "Aquarium",
        description: "Aquarium concept design",
        image: image_34,
    },
    {
        name: "Bananaland concept 1",
        description: "Bananaland concept 1",
        image: image_35,
    },
    {
        name: "Bananaland concept 2",
        description: "Bananaland concept 2",
        image: image_36,
    },
    // {
    //     name: "Williams Sonoma, Caruso",
    //     description: "Williams Sonoma pitch design",
    //     image: image_37,
    // },
    {
        name: "Carson & Tryon",
        description: "Carson & Tryon photosim",
        image: image_38,
    },
    {
        name: "Dinning",
        description: "Dinning room concept",
        image: image_39,
    },
    {
        name: "Dogpatch",
        description: "Dogpatch coffee bar",
        image: image_40,
    },
    {
        name: "Google",
        description: "Google speaker, product viz",
        image: image_41,
    },
    {
        name: "Kucherov House, living",
        description: "Kucherov House, livingroom",
        image: image_42,
    },
    {
        name: "Kucherov House, kitchen",
        description: "Kucherov House, kitchen",
        image: image_43,
    },
    {
        name: "Table de-construction",
        description: "Table de-construction",
        image: image_44,
    },
    {
        name: "Pike and Rose Aerial",
        description: "Pike and Rose Aerial",
        image: image_45,
    },
    {
        name: "Schnitzer Centre, Lobby",
        description: "Schnitzer Centre, Lobby",
        image: image_46,
    },
    {
        name: "Schnitzer Centre, Meeting room",
        description: "Schnitzer Centre, Meeting room",
        image: image_47,
    },
    {
        name: "Schnitzer Centre, Entrance",
        description: "Schnitzer Centre, Entrance",
        image: image_48,
    },
    {
        name: "Superspacer, product viz",
        description: "Superspacer, product viz",
        image: image_49,
    },
    {
        name: "San Pedro tower, Plaza",
        description: "Tower concept plaza, San Pedro, Mex.",
        image: image_50,
    },
    {
        name: "National Medal of Honor Museum",
        description: "National Medal of Honor Museum",
        image: image_51,
    },
];
